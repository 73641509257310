export const rootName = '/'
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const SERVICE_PLATFORM_PORT = process.env.REACT_APP_SERVICE_PLATFORM_PORT
export const AGENT_PORT = process.env.REACT_APP_AGENT_DEPLOYER_PORT
export const TASK_AGENT_API_URL = process.env.REACT_APP_TASK_AGENT_API_URL
export const SERVICE_PLATFORM_API_URL =
  process.env.REACT_APP_SERVICE_PLATFORM_API_URL
export const AGENT_DEPLOYER_API_URL =
  process.env.REACT_APP_AGENT_DEPLOYER_API_URL

export const THIRTY_SECONDS = 30 * 1000 // in miliseconds
export const ONE_MINUTE = 1000 * 60 // in miliseconds

export const APIURL = {
  CATEGORY_OPTIONS: 'registerationForm',
  NEW_SELLER_AGENT: 'create_seller_agent',
  BUYER_TASKS: 'task-agents',
  BUYER_APPOINTMENTS: 'booking-details',
  CREATE_TASK_AGENTS: 'create_task_agent',
  START_TASK_AGENT: 'start',
  PROPOSALS: 'proposals',
  SELLER_LIST: 'seller-agents',
  USER: 'User',
  CATEGORY: 'category/',
  SERVICE: 'service/',
  GOOGLE_CALENDAR_EVENTS:
    'https://www.googleapis.com/calendar/v3/calendars/primary/events',
}

export const serviceDurations = [
  { key: 30, name: '30 min' },
  { key: 60, name: '60 min' },
  { key: 90, name: '90 min' },
  { key: 120, name: '120 min' },
]

export const businessHoursConst = [
  { day: 'Monday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Tuesday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Wednesday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Thursday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Friday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Saturday', open: false, start_time: '09:00', end_time: '17:00' },
  { day: 'Sunday', open: false, start_time: '09:00', end_time: '17:00' },
]

export const calendarTime = [
  '00:00', // 12:00 AM
  '00:30', // 12:30 AM
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]
