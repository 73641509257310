import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import { createLogger } from "redux-logger";
import rootReducer from './reducers/index'

// const logger = createLogger({});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'seller'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const middlewares = [thunkMiddleware]
  // redux devtools
  const enhancers =
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose(applyMiddleware(...middlewares), composeWithDevTools())
      : compose(applyMiddleware(...middlewares))
  // create redux store
  const store = createStore(persistedReducer, {}, enhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configureStore
