import { toast } from '../../Components/Toast/Toast'
import { APIURL, GOOGLE_API_KEY, SERVICE_PLATFORM_PORT } from '../../Constants'
import { apiCallGet, apiCallPatch, apiCallPost } from '../../Services/axios'
import { addDatesToEvents, formatEvents } from '../../Services/UserService'
import sub from 'date-fns/sub'
import add from 'date-fns/add'

const accessToken = sessionStorage.getItem('csrfToken')

export const getSingleDayEvents = (curDate, nextDate) => {
  return () =>
    new Promise((resolve, reject) => {
      fetch(
        `${APIURL.GOOGLE_CALENDAR_EVENTS}?key=${GOOGLE_API_KEY}&orderBy=startTime&singleEvents=true${curDate ? '&timeMin='+curDate : ''}${nextDate ? '&timeMax='+nextDate : ''}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          // } else {
          //   toast.error('Token Expired! Please re-login.')
          }
        })
        .then((data) => {
          if (data?.items) {
            resolve(formatEvents(data.items))
          }
        }).catch((e) => {
          toast.error('Something went wrong.')
        })
    })
}

export const getAllCalendarEvents = () => {
  const minDate = sub(new Date(), {
    months: 3,
  }).toISOString()

  const maxDate = add(new Date(), {
    months: 2,
  }).toISOString()

  return () =>
    new Promise((resolve, reject) => {
      fetch(
        `${APIURL.GOOGLE_CALENDAR_EVENTS}?key=${GOOGLE_API_KEY}&orderBy=startTime&singleEvents=true&timeMin=${minDate}&timeMax=${maxDate}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          // } else {
          //   toast.error('Token Expired! Please re-login.')
          }
        })
        .then((data) => {
          if (data?.items) {
            resolve(addDatesToEvents(data?.items))
          }
        })
        .catch((error) => {
          toast.error('Something went wrong.')
        })
    })
}

export const getAllUsers = (params ={}) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(APIURL.USER, params, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const createUser = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPost(`${APIURL.USER}/`, data, {}, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getUserById = (id) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(`${APIURL.USER}/${id}`, {}, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const patchUser = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPatch(
        `${APIURL.USER}/${data.id}/`,
        data,
        {},
        false,
        true,
        SERVICE_PLATFORM_PORT,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}
