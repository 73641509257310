import { toast } from '../Components/Toast/Toast'
import { APIURL, rootName } from '../Constants/index'
import { apiCallDelete } from './axios'
import format from 'date-fns/format'
const unAuthMessages = ['jwt malformed']

export const handleTokenExpiry = (error) => {
  const s = error?.response?.status
  if (
    s === 401 ||
    (s === 400 && unAuthMessages.indexOf(error?.response?.data) > -1)
  ) {
    toast.error('Login session expired')
    setTimeout(() => {
      logout()
    }, 500)
  }
}

export const logout = async () => {
  const result = await apiCallDelete(APIURL.CLEAR_SESSION)
  clearUserCookie('csrfToken', '', 0)
  sessionStorage.clear()
  localStorage.clear()
  deleteAllCookies()
  window.location.replace('/')
}

export const getCookie = (c_name) => {
  let i
  let x
  let y
  const ARRcookies = document.cookie.split(';')
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='))
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1)
    x = x.replace(/^\s+|\s+$/g, '')
    if (x === c_name) {
      return decodeURI(y)
    }
  }
}

const isLoggedIn = () => {
  const csrfToken = sessionStorage.getItem('csrfToken')
  return csrfToken
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}
export function clearUserCookie(cname, cvalue, exMins) {
  const d = new Date()
  d.setTime(d.getTime() + exMins * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export function randomIdGenerator() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

export const getHoursMin = (time, type) => {
  let [hours, min] = time.split(':')
  return type == 'hours' ? hours : min
}

export const formatEvents = (list) => {
  return list.map((item) => ({
    title: item.summary,
    start: item.start.dateTime || item.start.date,
    end: item.end.dateTime || item.end.date,
  }))
}
export const addDatesToEvents = (list) => {
  let result = []
  list.forEach((element) => {
    const date = element?.start?.dateTime || element?.start?.date
    if (date) {
      result.push(format(new Date(date), 'dd-MM-yyyy'))
    }
  })
  return result
}

export const convertHoursIntoMin = (time) => {
  let [hours, minutes] = time.split(':') // split it at the colons

  // Hours are worth 60 minutes.
  minutes = +hours * 60 + +minutes

  return minutes
}

export const humanize = (str) => {
  var i,
    frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export const UserService = {
  isLoggedIn,
  clearUserCookie,
}
