import { actionTypes } from '../actions'

const initialState = {
  userInfo: {},
  isLoggedIn: false,
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload }

    case actionTypes.SAVE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
        isLoggedIn: !!Object.keys(action.payload || {}).length,
      }

    case actionTypes.SAVE_USER_LOGIN_TIME:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          login_time: action.payload.login_time,
        },
      }
    case actionTypes.SAVE_USER_EXPIRY_TIME:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          login_expire_time: action.payload.login_expire_time,
        },
      }
    default:
      return state
  }
}
