import { actionTypes } from '../actions'

const initialState = {
  sellerInfo: {},
  serviceInfo: {},
}

export default function seller(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_BUSINESS_INFO:
      return { ...state, sellerInfo: action.payload }
    case actionTypes.SAVE_SELLER_SERVICE_INFO:
      return { ...state, serviceInfo: action.payload }
    default:
      return state
  }
}
