import { combineReducers } from 'redux'
import loader from './loader'
import seller from './seller.reducer'
import user from './user.reducer'

const appReducer = combineReducers({
  loader,
  seller,
  user,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USERS_PERSIST') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
