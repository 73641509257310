import axios from 'axios'
import { toast } from '../Components/Toast/Toast'
import { setLoader } from '../Redux/actions'
import { storeInstance } from '../App'
import { handleTokenExpiry } from './UserService'
import {
  AGENT_DEPLOYER_API_URL,
  AGENT_PORT,
  SERVICE_PLATFORM_API_URL,
} from '../Constants/index'

// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    // config.headers["X-CSRF-Token"] = localStorage.getItem("csrfToken");
    // config.headers["Content-Type"] = "application/json";

    return config
  },
  (error) => {
    storeInstance.dispatch(setLoader(false))
    return error
  },
)
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    storeInstance.dispatch(setLoader(false))

    return response
  },
  (error) => {
    storeInstance.dispatch(setLoader(false))
    if (process.env.NODE_ENV !== 'development') {
      handleTokenExpiry(error)
    }

    handleError(error)

    return error
  },
)

function formatUrl(url, params, port) {
  const baseUrl =
    port == AGENT_PORT ? AGENT_DEPLOYER_API_URL : SERVICE_PLATFORM_API_URL

  if (Object.keys(params || {}).length) {
    const formattedParams = `?${new URLSearchParams(params).toString()}`
    return `${baseUrl}${url}/${formattedParams}`
  }
  return `${baseUrl}${url}`
}

function handleError(error) {
  if (error?.response?.status === 404)
    return toast.error('Something went wrong!')
  error?.response?.data?.msg && toast.error(error.response.data.msg)
  error?.response?.msg && toast.error(error.response.msg)
  error?.data?.message && toast.error(error.data.message)
  error?.response?.data?.message && toast.error(error.response.data.message)
  error?.response?.data?.errors?.message &&
    toast.error(error?.response?.data?.errors?.message)
}

function handleSuccess(res) {
  if (res.status === 200 || res.status === 201)
    res?.data?.message && toast.success(res.data.message)
  else {
    res?.data?.message && toast.info(res.data.message)
  }
}

export const apiCallPost = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true,
  port = AGENT_PORT,
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true))
    axios
      .post(formatUrl(url, params, port), data)
      .then((res) => {
        showToast && handleSuccess(res)
        resolve(res.data)
      })
      .catch((error) => {
        resolve(null)
      })
  })

export const apiCallGet = (
  url,
  params = {},
  showToast = false,
  showLoader = true,
  port = AGENT_PORT,
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true))
    axios
      .get(formatUrl(url, params, port))
      .then((res) => {
        showToast && handleSuccess(res)
        resolve(res.data)
      })
      .catch((error) => {
        resolve(null)
      })
  })

export const apiCallPut = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true,
  port = AGENT_PORT,
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true))
    axios
      .put(formatUrl(url, params, port), data)
      .then((res) => {
        showToast && handleSuccess(res)
        resolve(res.data)
      })
      .catch((error) => {
        resolve(null)
      })
  })

export const apiCallPatch = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true,
  port = AGENT_PORT,
) =>
  new Promise((resolve) => {
    if (showLoader) storeInstance.dispatch(setLoader(true))
    axios
      .patch(formatUrl(url, params, port), data)
      .then((res) => {
        showToast && handleSuccess(res)
        resolve(res.data)
      })
      .catch((error) => {
        resolve(null)
      })
  })

export const apiCallDelete = (
  url,
  params = {},
  showToast = false,
  showLoader = true,
  port = AGENT_PORT,
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true))
    axios
      .delete(formatUrl(url, params, port))
      .then((res) => {
        showToast && handleSuccess(res)
        resolve(res.data)
      })
      .catch((error) => {
        resolve(null)
      })
  })
