import React from 'react'
import './App.scss'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import Loader from './Components/Loader/Loader.jsx'
import Header from './Components/Header/Header'
import { Container } from 'react-bootstrap'
import { Provider } from 'react-redux'
import storeConfig from './Redux/store'
import history from './Routes/history'
import Routes from './Routes'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { PersistGate } from 'redux-persist/lib/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css'
const { store, persistor } = storeConfig()

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Header />
          <Container fluid className="mb-5 p-0 pb-5">
            <Loader />
            <Switch>
              <Routes />{' '}
            </Switch>
          </Container>
        </Router>
        <NotificationContainer />
      </PersistGate>
    </Provider>
  )
}

export const storeInstance = store
export default App
