import {
  AGENT_DEPLOYER_API_URL,
  APIURL,
  SERVICE_PLATFORM_PORT,
  TASK_AGENT_API_URL,
} from '../../Constants'
import { apiCallGet, apiCallPost, apiCallDelete } from '../../Services/axios'
import axios from 'axios'

export const getBuyerTasks = (params = {}) => {
  const port = SERVICE_PLATFORM_PORT
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const userInfo = getState().user?.userInfo
      const userId = userInfo?.id || userInfo?.userId || userInfo?.user_id
      const updatedParams = {
        ...params,
        user_id: userId,
      }
      if (!userId) return
      apiCallGet(APIURL.BUYER_TASKS, updatedParams, false, false, port)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getBuyerAppointments = (params = {}) => {
  const port = SERVICE_PLATFORM_PORT
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const userInfo = getState().user?.userInfo
      const userId = userInfo?.id || userInfo?.userId || userInfo?.user_id
      const updatedParams = {
        ...params,
        user_id: userId,
        per_page: 100,
      }
      apiCallGet(APIURL.BUYER_APPOINTMENTS, updatedParams, false, true, port)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const createBuyerTask = (data) => {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const userInfo = getState().user?.userInfo
      const userId = userInfo?.id || userInfo?.userId || userInfo?.user_id
      const updatedData = {
        ...data,
        user_id: userId,
      }

      apiCallPost(APIURL.CREATE_TASK_AGENTS, updatedData, {}, false, true)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const startTaskAgent = (port) => {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${AGENT_DEPLOYER_API_URL}agent/${port}/${APIURL.START_TASK_AGENT}`,
        )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getTaskDetails = (taskId) => {
  const port = SERVICE_PLATFORM_PORT
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(`${APIURL.BUYER_TASKS}/${taskId}/`, {}, false, true, port)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getProposals = (port, page = 1) => {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${AGENT_DEPLOYER_API_URL}agent/${port}/${APIURL.PROPOSALS}?page=${page}`,
        )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const bookProposal = (data) => {
  const { port, proposalId, agentId } = data
  const body = {
    proposal_id: proposalId,
    agent_id: agentId,
  }
  return () =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${AGENT_DEPLOYER_API_URL}agent/${port}/confirm?proposal_id=${proposalId}&agent_id=${agentId}`,
        )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const confirmProposal = (port, requestId) => {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${AGENT_DEPLOYER_API_URL}agent/${port}/booking_status?request_id=${requestId}`,
        )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const deleteTask = (agentAddress) => {
  const port = SERVICE_PLATFORM_PORT
  return () =>
    new Promise((resolve, reject) => {
      apiCallDelete(
        `${APIURL.BUYER_TASKS}/${agentAddress}`,
        {},
        true,
        true,
        port,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}
