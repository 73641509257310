import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'

import Loader from '../Components/Loader/Loader'

import { UserService } from '../Services/UserService'

import { privateRouteList, publicRouteList } from './routeList'

export default function Routes() {
  const isLogin = UserService.isLoggedIn()
  // const isLogin = true

  const getRouteList = (routes) => {
    return routes.map(({ path, component }, key) => (
      <Route key={key} path={path} component={component} exact />
    ))
  }

  return (
    <Suspense fallback={<Loader />}>
      <>
        {getRouteList(publicRouteList)}
        {isLogin ? getRouteList(privateRouteList) : <Redirect to="/signup" />}
      </>
    </Suspense>
  )
}
