import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Header.scss'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Dropdown } from 'react-bootstrap'
import notification from '../../Assets/images/notification.svg'
import line from '../../Assets/images/Line.svg'
import { UserService } from '../../Services/UserService'
import { useLocation, useHistory } from 'react-router'
import { GOOGLE_API_KEY, GOOGLE_CLIENT_ID } from '../../Constants'
import { actionTypes, getAllUsers } from '../../Redux/actions'
import { createUser, patchUser } from '../../Redux/actions'

export default function Header() {
  const timeRef = useRef(null)
  const gapi = window.gapi
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [currLoc, setCurrLoc] = useState('')
  const isLogin = UserService.isLoggedIn()
  const userInfo = useSelector((state) => state.user.userInfo)
  // const userLogin = useSelector((state) => state.user.isLoggedIn)

  const onLogoutSuccess = async () => {
    dispatch({
      type: actionTypes.LOGOUT_USERS_PERSIST,
    })
    dispatch({
      type: actionTypes.SAVE_USER_INFO,
      payload: {},
    })

    clearTimeout(timeRef.current)
    sessionStorage.clear()
    localStorage.clear()
    window.location.reload()
  }

  const syncUserWithDB = () => {
    dispatch(getAllUsers({ email: userInfo.email })).then((res) => {
      if (res?.count === 1) {
        // login
        const data = {
          ...userInfo,
          id: res.results[0]?.id,
          signin_token: sessionStorage.getItem('csrfToken'),
        }
        dispatch(patchUser(data)).then((res) => {
          dispatch({
            type: actionTypes.SAVE_USER_INFO,
            payload: {
              ...data,
              user_id: data.id,
              userId: data.id,
            },
          })
        })
      } else {
        // register
        const data = {
          signin_token: sessionStorage.getItem('csrfToken'),
          email: userInfo.email,
          phone_number: '',
          street_address: '',
          latitude: 0,
          longitude: 0,
          postcode: '',
          city: '',
          role: 'TASK',
        }

        dispatch(createUser(data)).then((res) => {
          if (!res) return null
          const updatedUserInfo = {
            ...userInfo,
            id: res.id,
            phoneNumber: res.phone_number,
            streetAddress: res.street_address,
            latitude: res.latitude,
            longitude: res.longitude,
            existingUser: !!res.id,
            postcode: '',
            city: '',
          }

          dispatch({
            type: actionTypes.SAVE_USER_INFO,
            payload: updatedUserInfo,
          })
        })
      }
    })
  }

  useEffect(() => {
    setCurrLoc(location.pathname)
  }, [location])

  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0 && isLogin) {
      let result = userInfo.login_expire_time - new Date().getTime()
      timeRef.current = setTimeout(() => {
        refreshToken()
      }, result)
    }
  }, [userInfo, isLogin])

  useEffect(() => {
    if (gapi && isLogin) {
      gapi.load('client:auth2', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          clientId: GOOGLE_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/calendar.events',
        })
      })
      syncUserWithDB()
    }
  }, [isLogin])

  const refreshToken = async () => {
    if (!gapi?.auth2) return
    gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .reloadAuthResponse()
      .then((res) => {
        sessionStorage.setItem('csrfToken', res.access_token)
        dispatch({
          type: actionTypes.SAVE_USER_LOGIN_TIME,
          payload: {
            login_time: res.first_issued_at,
          },
        })
        dispatch({
          type: actionTypes.SAVE_USER_EXPIRY_TIME,
          payload: {
            login_expire_time: res.expires_at,
          },
        })
      })
  }

  return (
    <Navbar sticky="top" bg="light" variant="light" className="top-bar">
      <Navbar.Brand>
        <h2
          className="logo-text"
          onClick={() => isLogin && history.push('/buyer-profile')}
        >
          Logo
        </h2>
      </Navbar.Brand>

      {isLogin && (
        <Navbar.Collapse id="basic-navbar-nav" className="rightbar">
          <Nav className="me-auto">
            <Nav.Link
              href="/buyer-profile"
              className={currLoc === '/buyer-profile' ? 'active' : ''}
            >
              Tasks
            </Nav.Link>
            <Nav.Link
              className={currLoc === '/appointments' ? 'active' : ''}
              href="/appointments"
            >
              Appointments
            </Nav.Link>

            <Nav.Link>
              <div className="notification-col">
                <img src={notification} alt="notification" />
                <span className="notify-text">2</span>
              </div>
            </Nav.Link>
            <div className="divider">
              <img src={line} alt="divider" />
            </div>

            <Dropdown>
              <Dropdown.Toggle className="headerProfile" id="dropdown-basic">
                <img src={userInfo.imageUrl} alt="profile" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/user-profile">User Profile</Dropdown.Item>
                <Dropdown.Item href="/admin-dashboard">
                  Admin Dashboard
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onLogoutSuccess()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      )}
    </Navbar>
  )
}
