import React from 'react'

const BuisnessInfoForm = React.lazy(() =>
  import('../Pages/SellerPages/BuisnessInfoForm/BuisnessInfoForm.jsx'),
)
const SellerProfile = React.lazy(() => import('../Pages/SellerPages/Profile'))
const BuyerDashboard = React.lazy(() =>
  import('../Pages/BuyerPages/BuyerDashboard'),
)
const TaskDetails = React.lazy(() => import('../Pages/BuyerPages/TaskDetails'))
const Signup = React.lazy(() => import('../Pages/SignupPage/Signup'))
const AdminDashboard = React.lazy(() =>
  import('../Pages/AdminPages/AdminDashboard/index.jsx'),
)
const Appointments = React.lazy(() => import('../Pages/Appointments/Index'))

export const privateRouteList = [
  {
    path: '/register-business',
    component: BuisnessInfoForm,
  },
  {
    path: '/user-profile',
    component: SellerProfile,
  },
  {
    path: '/buyer-profile',
    component: BuyerDashboard,
  },
  {
    path: '/task-details/:taskId',
    component: TaskDetails,
  },
  {
    path: '/admin-dashboard',
    component: AdminDashboard,
  },
  {
    path: '/appointments',
    component: Appointments,
  },
]

export const publicRouteList = [
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/',
    component: Signup,
  },
]
