export * from './loader.actions'
export * from './seller.actions'
export * from './buyer.actions'
export * from './user.actions'
export * from './general.actions'

export const actionTypes = {
  LOGOUT_USERS_PERSIST: 'LOGOUT_USERS_PERSIST',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  SAVE_USER_INFO: 'SAVE_USER_INFO',
  SAVE_USER_LOGIN_TIME: 'SAVE_USER_LOGIN_TIME',
  SAVE_USER_EXPIRY_TIME: 'SAVE_USER_EXPIRY_TIME',
  SAVE_BUSINESS_INFO: 'SAVE_BUSINESS_INFO',
  SAVE_SELLER_SERVICE_INFO: 'SAVE_SELLER_SERVICE_INFO',
}
