import { AGENT_PORT, APIURL, SERVICE_PLATFORM_PORT } from '../../Constants'
import { apiCallGet, apiCallPost } from '../../Services/axios'

export const getCategoryOptions = () => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(
        APIURL.CATEGORY_OPTIONS,
        {},
        false,
        true,
        SERVICE_PLATFORM_PORT,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const createNewSellerAgent = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.NEW_SELLER_AGENT, data, {}, false, true, AGENT_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getSellersList = (params = {}) => {
  const port = SERVICE_PLATFORM_PORT
  const updatedParams = {
    ...params,
    per_page: 100,
  }
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(APIURL.SELLER_LIST, updatedParams, false, false, port)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const getSellerDetails = (agentAddress) => {
  const port = SERVICE_PLATFORM_PORT
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(
        `${APIURL.SELLER_LIST}/${agentAddress}`,
        {},
        false,
        false,
        port,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}
