import { APIURL, SERVICE_PLATFORM_PORT } from '../../Constants'
import {
  apiCallDelete,
  apiCallGet,
  apiCallPatch,
  apiCallPost,
} from '../../Services/axios'

export const getCategoryList = () => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallGet(APIURL.CATEGORY, {}, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const addCategory = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.CATEGORY, data, {}, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const editCategory = (id, data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPatch(
        `${APIURL.CATEGORY}${id}/`,
        data,
        {},
        false,
        true,
        SERVICE_PLATFORM_PORT,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const addService = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.SERVICE, data, {}, false, true, SERVICE_PLATFORM_PORT)
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const editService = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallPatch(
        `${APIURL.SERVICE}${data.id}/`,
        data,
        {},
        false,
        true,
        SERVICE_PLATFORM_PORT,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}

export const deleteService = (id) => {
  return () =>
    new Promise((resolve, reject) => {
      apiCallDelete(
        `${APIURL.SERVICE}${id}/`,
        {},
        false,
        true,
        SERVICE_PLATFORM_PORT,
      )
        .then((result) => {
          resolve(result)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
}
