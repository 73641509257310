import { NotificationManager } from 'react-notifications'

class Toaster {
  success(message, title) {
    NotificationManager.success(message, title || 'Success', 3000)
  }
  warning(message, title) {
    NotificationManager.warning(message, title || 'Warning', 3000)
  }

  error(message, title) {
    NotificationManager.error(message, title || 'Error', 3000)
  }
  info(message, title, cb = () => {}) {
    NotificationManager.info(message, title || 'Info', 3000, cb)
  }
}

export const toast = new Toaster()
